body {
  margin: 0;
  margin: auto;
  background: #f3f2f1;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  overflow-x: hidden;
}

.app {
  background: #f3f2f1;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  position: fixed;
  overflow: scroll;
  margin: auto;
}

/* Topbar */
.topbar {
  position: sticky;
  top: 0;
  z-index: 1020;
  height: 32px;
  display: flex;
  padding: 8px;
  font-size: 22px;
  color: #fff;
  background: #33444a;
}

.topbar-toggler-icon {
  position: absolute;
  width: 48px;
  right: 48px;
  text-align: center;
  font-size: 24px;
  margin-right: -48px;
  cursor:pointer;
}

/* Sidebar Styles */
#wrapper {
  padding: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-right: 48px;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  right: 48px;
  width: 0;
  height: 100%;
  margin-right: -48px;
  overflow-y: auto;
  background: #33444a;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#sidebar-wrapper.toggled {
  width: 48px;
}

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 48px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  text-align: center;
  line-height: 48px;
  font-size: 24px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #999999;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}

.sidebar-nav li a:active, .sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav-selected {
    color: #fff!important;
}

@media(min-width:768px) {
  #wrapper {
    padding-right: 48px;
  }
  #sidebar-wrapper {
    width: 48px;
  }
  .topbar-toggler-icon {
      display: none;
  }
}